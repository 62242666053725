        import React, { useEffect, useState } from 'react';
        import axios from 'axios';
        import CategoryCard from './CategoryCard';
        import "../style/CategoryCard.css";
        import Footer from './Footer';

        const CategoryData = () => {
        const [Categories, setCategories] = useState([]);
        const [Permanant , setPermanant] = useState([])
        const [Result,setResult] = useState();


        useEffect(() => {
            const fetchData = async () => {
              try{
                const response = await fetch('https://divineyogaforwomen.frappe.cloud/api/method/lms.lms.doctype.lms_category.api.get_category_details', {
                  method: 'GET',
                  headers: {
                    'Authorization': 'Token a05d3fcba67eb13:4e965d4f9c818d3'
                  }
                });
                const result = await response.json();
                setCategories(result.message.data);
                setResult(true)
              }
              catch(error){
                setResult(false)
              }
            };




            
        
            fetchData();
          }, []);

          useEffect(()=>{
            if(Categories.length>0){
              var Initial = [];
              var Second = [];
              const targetTitles = [
                "Regular Yoga",
                "Weight Loss Challenge",
                "Pregnancy Yoga",
                "Yoga Teacher Training Course",
                "Diet Programs",
                "Belly Fat Challenge"
              ];
              
              targetTitles.forEach((title) => {
                const result = Categories.find((item) => item.category_titel === title);
                if (result) {
                  Initial.push(result);
                }
              });
              
              Second = Categories.filter((item) => !targetTitles.includes(item.category_titel));
              var final=[...Initial,...Second];
              setPermanant(final);
            }
          },[Categories])

          
          
          
          
        return (
          <>
          <h4 className='Category-heading-one'>CATEGORY</h4>
            <div className='CategoryData-main-div'>
              {
                Result==true ?
                Permanant.map((item,index)=>(
                  <div key={index} className='CategoryData-Card-division'>
                    <CategoryCard item={item} itemRoute={item.route}/>
                  </div>
                ))
                :
                <h4 className='Category-heading-one'>No Data Found</h4>
              }
               
            </div>
          </>
        );
        };

        export default CategoryData;
